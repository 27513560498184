// Helper classes used across whole application
html * {
  font-family: Open Sans;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}

.float-none {
  float: none !important;
}

.font-bold-400 {
  font-weight: 400;
}

.font-bold-500 {
  font-weight: 500;
}

.font-bold-600 {
  font-weight: 600;
}

.top-none {
  top: 0 !important;
}

.padding-overall {
  padding: 0.5rem;
}

.text-red {
  color: @red;
}

.text-black {
  color: @black-light;
}

.text-gray {
  color: @new-gray-semi-black;
}

.text-cool-gray {
  color: @cool-gray3;
}

.right-none {
  right: 0 !important;
}

.position-top-0 {
  top: 0;
}

.position-right-0 {
  right: 0;
}

.position-bottom-0 {
  bottom: 0;
}

.position-left-0 {
  left: 0;
}

.padding-none {
  padding: 0;
}

.padding-xs {
  padding: @xs;
}

.padding-xxs {
  padding: @xxs;
}

.padding-s {
  padding: @s;
}

.padding-m {
  padding: @m;
}

.padding-l {
  padding: @l;
}

.padding-xl {
  padding: @xl;
}

.padding-top-none {
  padding-top: 0 !important;
}

.padding-right-none {
  padding-right: 0 !important;
}

.padding-bottom-none {
  padding-bottom: 0 !important;
}

.padding-left-none {
  padding-left: 0 !important;
}

.padding-top-xxs {
  padding-top: @xxs;
}

.padding-right-xxs {
  padding-right: @xxs;
}

.padding-bottom-xxs {
  padding-bottom: @xxs;
}

.padding-left-xxs {
  padding-left: @xxs;
}

.padding-top-xs {
  padding-top: @xs;
}

.padding-right-xs {
  padding-right: @xs;
}

.padding-bottom-xs {
  padding-bottom: @xs;
}

.padding-left-xs {
  padding-left: @xs;
}

.padding-left-x {
  padding-left: @x;
}

.padding-right-x {
  padding-right: @x;
}

.padding-top-s {
  padding-top: @vertical-s;
}

.padding-top-es {
  padding-top: @s;
}

.padding-right-vs {
  padding-right: @vertical-s;
}

.padding-right-s {
  padding-right: @s;
}

.padding-bottom-s {
  padding-bottom: @vertical-s;
}

.padding-bottom-16 {
  padding-bottom: @s;
}

.padding-left-s {
  padding-left: @s;
}

.padding-left-10 {
  padding-left: 0.625rem;
}

.padding-left-sm {
  padding-left: @sm;
}

.padding-top-m {
  padding-top: @m;
}

.padding-right-m {
  padding-right: @m;
}

.padding-bottom-m {
  padding-bottom: @m;
}

.padding-left-m {
  padding-left: @m;
}

.padding-top-l {
  padding-top: @vertical-l;
}

.padding-right-l {
  padding-right: @l;
}

.padding-top-32 {
  padding-top: @l;
}

.padding-bottom-l {
  padding-bottom: @vertical-l;
}

.padding-left-l {
  padding-left: @l;
}

.padding-top-xl {
  padding-top: @xl;
}

.padding-top-xl-s {
  padding-top: @xl-s;
}

.padding-right-xl {
  padding-right: @xl;
}

.padding-bottom-xl {
  padding-bottom: @xl;
}

.padding-left-xl {
  padding-left: @xl;
}

.padding-top-xxl {
  padding-top: @xxl;
}

.padding-right-xxl {
  padding-right: @xxl;
}

.padding-bottom-xxl {
  padding-bottom: @xxl;
}

.padding-left-xxl {
  padding-left: @xxl;
}

.padding-left-vs {
  padding-left: @vertical-s;
}

.margin-none {
  margin: 0;
}

.margin-top-none {
  margin-top: 0 !important;
}

.margin-right-none {
  margin-right: 0 !important;
}

.margin-bottom-none {
  margin-bottom: 0 !important;
}

.margin-left-none {
  margin-left: 0 !important;
}

.margin-top-xxs {
  margin-top: @xxs;
}

.margin-top-minus-xxs {
  margin-top: -@xxs;
}

.margin-top-minus-m {
  margin-top: -@m;
}

.margin-right-xxs {
  margin-right: @xxs;
}

.margin-right-minus-xxs {
  margin-right: -@xxs;
}

.margin-bottom-xxs {
  margin-bottom: @xxs;
}

.margin-bottom-sm {
  margin-bottom: @sm;
}

.margin-bottom-vertical-s {
  //0.75rem
  margin-bottom: @vertical-s;
}

.margin-bottom-minus-xxs {
  margin-bottom: -@xxs;
}

.margin-left-xxs {
  margin-left: @xxs;
}

.margin-left-minus-xxs {
  margin-left: -@xxs;
}

.margin-top-xs {
  margin-top: @xs;
}

.margin-right-xs {
  margin-right: @xs;
}

.margin-bottom-xs {
  margin-bottom: @xs;
}

.margin-left-xs {
  margin-left: @xs;
}

.margin-left-minus-xs {
  margin-left: -@xs;
}

.margin-top-s {
  margin-top: @vertical-s;
}

.margin-top-minus-s {
  margin-top: -@s;
}

.margin-top-sm {
  margin-top: @sm;
}

.margin-right-s {
  margin-right: @s;
}

.margin-right-minus-s {
  margin-right: -@s;
}

.margin-bottom-s {
  margin-bottom: @vertical-s;
}

.margin-bottom-es {
  margin-bottom: @s;
}

.margin-bottom-minus-s {
  margin-bottom: -@s;
}

.margin-left-s {
  margin-left: @s;
}

.margin-left-minus-s {
  margin-left: -@s;
}

.margin-left-minus-m {
  margin-left: -@m;
}

.margin-top-m {
  margin-top: @m;
}

.margin-right-m {
  margin-right: @m;
}

.margin-bottom-m {
  margin-bottom: @m;
}

.margin-left-m {
  margin-left: @m;
}

.margin-top-l {
  margin-top: @vertical-l;
}

.margin-top-l2 {
  margin-top: @l;
}

.margin-right-l {
  margin-right: @l;
}

.margin-bottom-l {
  margin-bottom: @vertical-l;
}

.margin-left-l {
  margin-left: @l;
}

.margin-left-x {
  margin-left: @x;
}

.margin-top-xl {
  margin-top: @xl;
}

.margin-right-xl {
  margin-right: @xl;
}

.margin-bottom-xl {
  margin-bottom: @xl;
}

.margin-left-xl {
  margin-left: @xl;
}

.margin-top-xxl {
  margin-top: @xxl;
}

.margin-right-xxl {
  margin-right: @xxl;
}

.margin-bottom-xxl {
  margin-bottom: @xxl;
}

.margin-left-xxl {
  margin-left: @xxl;
}

.margin-left-minus-xxl {
  margin-left: -@xxl;
}

.margin-left-minus-sm {
  margin-left: -@sm;
}

.margin-left-sm {
  margin-left: @sm;
}

.margin-right-vs {
  margin-right: @vertical-s;
}

.height-xxs {
  height: @xxs;
}

.height-xs {
  height: @xs;
}

.height-s {
  height: @s;
}

.height-m {
  height: @m;
}

.height-l {
  height: @l;
}

.height-xl {
  height: @xl;
}

.height-xxl {
  height: @xxl;
}

.width-xxs {
  width: @xxs;
}

.width-xs {
  width: @xs;
}

.width-s {
  width: @s;
}

.width-m {
  width: @m;
}

.width-l {
  width: @l;
}

.width-xl {
  width: @xl;
}

.width-xxl {
  width: @xxl;
}

.top-border {
  border-top: 0.0625rem solid @new-gray-light1;
}

.bottom-border {
  border-bottom: 0.0625rem solid @new-gray-light1;
}

.right-border {
  border-right: 0.0625rem solid @new-gray-light1;
}

.left-border {
  border-left: 0.0625rem solid @new-gray-light1;
}

.border-none {
  border: 0 !important;
}

.border-left-none {
  border-left: 0 !important;
}

.border-right-none {
  border-right: 0 !important;
}

.border-top-none {
  border-top: 0 !important;
}

.border-bottom-none {
  border-bottom: 0 !important;
}

.box-shadow-none {
  box-shadow: none;
}

.font-size-11 {
  font-size: 0.6875rem;
  /* 11px */
}

.font-size-12 {
  font-size: 0.75rem;
  /* 12px */
}

.font-size-13 {
  font-size: 0.8125rem;
  /* 13px */
}

.font-size-14 {
  font-size: 0.875rem;
  /* 14px */
}

.font-size-16 {
  font-size: 1rem;
  /* 16px */
}

.font-size-18 {
  font-size: 1.125rem;
  /* 18px */
}

.font-size-20 {
  font-size: 1.25rem;
  /* 20px */
}

.font-size-22 {
  font-size: 1.375rem;
  /* 22px */
}

.font-size-24 {
  font-size: 1.5rem;
  /* 24px */
}

.font-size-28 {
  font-size: 1.75rem;
  /* 28px */
}

.font-size-32 {
  font-size: 2rem;
  /* 32px */
}

.no-break {
  white-space: nowrap;
}

.color-red {
  color: @red;
}

.url-link {
  color: @blue-normal;
  .cursor-clickable;
  text-decoration: underline;

  &.text-decoration-none {
    &:not(:hover) {
      text-decoration: none;
    }
  }
}

.link {
  color: @blue-normal;
  cursor: pointer;
}

.cursor-clickable {
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  -ms-word-break: break-word;
  word-break: break-word;
  word-wrap: break-word;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-row-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.z-index {
  z-index: 1000;
}

.box-shadow-xs {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-top {
  vertical-align: top;
}

.text-red {
  color: @red;
}

.text-green {
  color: @green-bright;
}

.text-green-2 {
  color: @dds-green;
}

.hyperlink-green {
  color: @dds-green;
}

.accepted {
  color: #26890d !important;
}

.serve-with-conditions {
  color: #ed8b00 !important;
}

.do-not-serve {
  color: #da291c !important;
}

.accepted-container {
  .accepted;
  background: #43b02a14 !important;
  padding: 0 0.5rem !important;
}

.serve-with-conditions-container {
  .serve-with-conditions;
  background: #ed8b0014 !important;
  padding: 0 0.5rem !important;
}

.do-not-serve-container {
  .do-not-serve;
  background: #fceeed !important;
  padding: 0 0.5rem !important;
}

.eclipse-section-header {
  font-size: 1rem;
  /*16px*/
  font-weight: 600;
  color: @new-black;
}

.eclipse-small-header-grey {
  font-size: 0.75rem;
  /*12px*/
  font-weight: 400;
  color: @new-black-light;
}

.eclipse-header-grey {
  font-size: 0.875rem;
  /*14px*/
  font-weight: 600;
  color: @new-black-light;
}

.eclipse-header {
  font-size: 0.875rem;
  /*14px*/
  font-weight: 600;
  color: @new-black;
}

.eclipse-main-tab-header {
  font-size: 1.5rem;
  /*24px*/
  font-weight: 700;
  color: @new-black;
}

.eclipse-content {
  font-size: 0.875rem;
  /*14px*/
  font-weight: 400;
  color: @new-black;
}

.eclipse-content-grey {
  font-size: 0.875rem;
  /*14px*/
  font-weight: 400;
  color: @new-black-light;
}

.word-wrap {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.text-break {
  word-break: break-word;
  -ms-word-wrap: break-word;
}

.font-bold {
  font-weight: bold;
}

.checkbox-label {
  position: relative;
  bottom: 0.1875rem;
  font-size: 0.75rem;
}

.nobold {
  font-weight: normal;

  strong {
    font-weight: normal;
  }
}

.cursor-disabled {
  cursor: not-allowed;
}

.clear-both {
  clear: both;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.visibility-hidden {
  visibility: hidden;
}

dds-accordion-item .dds-accordion__header {
  padding: 0rem;
}

dds-sticker .dds-daypicker__header .dds-daypicker__name {
  padding: 0.313rem 0.75rem 0.438rem 0.9rem !important;
}

.dds-accordion__item_open>.dds-accordion__body {
  padding: 0rem;
  display: block;
  padding-left: 0.2rem;
}

.dds-accordion__item_open>.dds-accordion__body {
  padding: 0rem;
  display: block;
}

.general-information-questions {
  .section {
    .section-container1 {
      .padding-addrole {
        .dds-btn__content {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .text-label {
        .dds-btn__content {
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }
}

.client-status-acceptance-container {
  .section {
    .section-container1 {
      .dds-uploader__label {
        .dds-btn__text {
          padding-left: 0.75rem;
        }
      }
    }
  }
}

.dds-header__project-name {
  color: #fff;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  font-weight: 700;

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  padding-top: 0.3rem;
}

//   .dds-header__right-wrap{
//     width: 80%;
//   }
.dds-header__search {
  width: 50%;

  .customSearch {
    width: 100%;

    .dds-search__input {
      font-size: 0.6rem;
      padding-right: 1rem;
    }
  }
}

.dds-header__btn-icons .dds-btn__content {
  padding: 0rem !important;
  min-width: 0rem !important;
}

.customHeaderButton {
  margin-right: 0rem !important;
}

.dds-header__btn-icons .customChatButton {
  padding-left: 0.75rem;

  .dds-btn__content {
    background-color: #fff;
    min-height: 2rem;

    .dds-btn__text {
      color: #000000;
      padding-left: 0.5rem;
    }

    .dds-icon_chat__s__stroke {
      color: #000000;
      margin-left: 0rem;
      padding-top: 0.2rem;
      font-size: 0.9rem;
      font-weight: 100;
    }
  }
}

.dds-header-icons {
  padding-left: 0rem;
}

.dds-header__main {
  cursor: pointer;
}

.dds-header__profile {
  margin-left: 1rem;
}

.dds-header__container {
  max-width: 100%;
}

.icon-required-yellow {
  height: 0.8rem;
  width: 0.8rem;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.dds-modal-init {
  z-index: 1000;
}

.ul-style-none {
  list-style-type: none;
}

.asterisk-before-text::before {
  content: "*";
}

.vertical-line-green {
  width: 0.09rem;
  background-color: #43b02a;
  height: 90%;
  position: absolute;
  top: 1rem;
  left: 0.5rem;
}

.vertical-line-green-s {
  width: 0.09rem;
  background-color: #43b02a;
  height: 6.5rem;
  top: 4rem;
  left: 0.5rem;
}

.no-scroll {
  overflow: hidden;
}

.dds-profile__user-info .dds-profile__position {
  white-space: pre-wrap;
}

.light-blue {
  background-color: @black-light;
}

.color-dark-blue {
  color: @blue-light;
}

.styled-box {
  width: fit-content;
  background-color: #fff8d9;
  border-left: 0.4rem solid #ffe05e;
  padding: 0.6rem;
  box-sizing: border-box;
  margin-bottom: 1.25rem;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.w-100 {
  width: 100%;
}

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.line-height-l {
  line-height: @l;
}

.line-height-sm {
  line-height: @sm;
}

.line-height-m {
  line-height: @m;
}

.line-height-s {
  line-height: @s;
}

.dds-daypicker__item>.dds-btn>.dds-btn__content {
  padding: 0rem !important;
  justify-content: center;
}

.dds-monthpicker__item>.dds-btn>.dds-btn__content {
  padding: 1.25rem 0.375rem 0.438rem 0.438rem !important;
  justify-content: center;
}

.dds-yearpicker__item>.dds-btn>.dds-btn__content {
  padding: 1.25rem 0.375rem 0.438rem 0.5rem !important;
  justify-content: center;
}
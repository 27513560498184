@import "variables.less";

.eclipse-icon {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: inline-block;
}

.icon-vs {
  width: @vertical-s;
  height: @vertical-s;
}

.icon-s {
  width: @s;
  height: @s;
}

.icon-m {
  width: @m;
  height: @m;
}

.icon-sm {
  width: @sm;
  height: @sm;
}

.icon-l {
  width: @l;
  height: @l;
}

.icon-x {
  width: @x;
  height: @x;
}

.icon-xl {
  width: @xl;
  height: @xl;
}

.icon-xxxl {
  width: @xxxl;
  height: @xxxl;
}

.icon-expand-black {
  background-image: data-uri(
    "../assets/images/dds-icons/expand-icon-black.svg"
  );
}

.icon-expand-grey {
  background-image: data-uri("../assets/images/dds-icons/expand-icon-grey.svg");
}

.icon-collapse-black {
  background-image: data-uri(
    "../assets/images/dds-icons/collapse-icon-black.svg"
  );
}

.icon-required-yellow {
  background-image: data-uri(
    "../assets/images/dds-icons/required-yellow-icon.svg"
  );
}

.icon-filter {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-filter.svg");
}

.icon-tile-CompleteNcaForm {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-briefcase.png"
  );
}

.icon-tile-CompleteNcaForm-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-briefcase-active.png"
  );
}

.icon-tile-CompleteNcaForm-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-briefcase-darker.png"
  );
}

.icon-tile-PreScreenNcaForm,
.icon-tile-Reviewed,
.icon-tile-Inprocess {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-pencil.png");
}

.icon-tile-PreScreenNcaForm-active,
.icon-tile-Reviewed-active,
.icon-tile-Inprocess-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-pencil-active.png"
  );
}

.icon-tile-PreScreenNcaForm-darker,
.icon-tile-Reviewed-darker,
.icon-tile-Inprocess-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-pencil-darker.png"
  );
}

.icon-tile-NcaNotRequired,
.icon-tile-Completed {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-ticks.png");
}

.icon-tile-NcaNotRequired-active,
.icon-tile-Completed-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-ticks-active.png"
  );
}

.icon-tile-NcaNotRequired-darker,
.icon-tile-Completed-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-ticks-darker.png"
  );
}

.icon-tile-OnHold {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-time.png");
}

.icon-tile-OnHold-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-time-active.png"
  );
}

.icon-tile-OnHold-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-time-darker.png"
  );
}

.icon-tile-NcaDiscontinued,
.icon-tile-Discontinued {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-block.png");
}

.icon-tile-NcaDiscontinued-active,
.icon-tile-Discontinued-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-block-active.png"
  );
}

.icon-tile-NcaDiscontinued-darker,
.icon-tile-Discontinued-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-block-darker.png"
  );
}

.icon-tile-CompleteMarsNcaForm {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-person.png");
}

.icon-tile-CompleteMarsNcaForm-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-active.png"
  );
}

.icon-tile-CompleteMarsNcaForm-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-darker.png"
  );
}
.icon-tile-ClientContinuanceDue,
.icon-tile-Submitted {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-arrow.png"
  );
}

.icon-tile-ClientContinuanceDue-active,
.icon-tile-Submitted-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-arrow-active.png"
  );
}

.icon-tile-ClientContinuanceDue-darker,
.icon-tile-Submitted-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-arrow-darker.png"
  );
}
.icon-tile-CCDue {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-with-arrow.svg"
  );
}

.icon-tile-CCDue-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-with-arrow-active.svg"
  );
}

.icon-tile-CCDue-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-person-with-arrow-darker.png"
  );
}

.icon-tile-NCAPendingApproval {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-home.svg");
}

.icon-tile-NCAPendingApproval-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-home-active.svg"
  );
}

.icon-tile-NCAPendingApproval-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-home-darker.png"
  );
}

.icon-tile-RecentReviews {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-clock.svg");
}

.icon-tile-RecentReviews-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-clock-active.svg"
  );
}

.icon-tile-RecentReviews-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-clock-darker.svg"
  );
}

.icon-tile-Initiated {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-initiated.svg"
  );
}

.icon-tile-Initiated-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-initiated-active.svg"
  );
}

.icon-tile-Initiated-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-initiated-darker.svg"
  );
}

.icon-tile-ExternalCommunications {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-extcomm.svg");
}

.icon-tile-ExternalCommunications-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-extcomm-active.svg"
  );
}

.icon-tile-ExternalCommunications-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-extcomm-darker.svg"
  );
}

.icon-tile-GlobalEngagement {
  background-image: data-uri("../assets/images/dds-icons/dds-icon-globe.svg");
}

.icon-tile-GlobalEngagement-active {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-globe-active.svg"
  );
}

.icon-tile-GlobalEngagement-darker {
  background-image: data-uri(
    "../assets/images/dds-icons/dds-icon-globe-darker.svg"
  );
}

.icon-info {
  background-image: data-uri("../assets/images/dds-icons/dds-info-icon.svg");
}
.icon-chat-outline {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAALiIAAC4iAari3ZIAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuNWRHWFIAAAF2SURBVDhP5dQ9KEZRHMfx6y2SFKUoAxbKZjAwGDDIoMhgUMpMIYvVwCrKZCCLLCY9USxSUl4GLyFSJJJBlHe+v9u99Tzd89x77uxXn3LOo/PyP+ceJyLF6MQYxjGAeuQgdkowixe84gYXeMQXztGDLFilDrd4wAjKkAklD/p9GR+Y9/pCUwWtZgel6kiTDHRBu5iDP2kg2soGtLVCdVhEJfhEu9sypAGqV7PbsotWuIpd7+9ApnEM6+J7aYFWW+62kqIa7WMB+V7bJrpaFVBtu9WRnGxcQif6hilEDVyJI2iVPxhESjTjFXRQo9A/VSMsmvgavXjHMFLiDzqJGnyjEWFZxDoK8IS0g07gHw6qi3uATehFinNQ/dCNGUIgrbjDM2bUERG9EyfQHf1FH4zRxdcbahuVrQP6vKN2Zozp2y6CvsRtxPq8tRK9XDqMFegBz0UbTnGPWKvU7IdQzXxn0AS6IWtQbWOnCQnsYQtL0A2phSGO8wfCt2IBAkG72gAAAABJRU5ErkJggg==);
}
.icon-pinned {
  background-image: data-uri("../assets/images/dds-icons/icon.svg");
}

.icon-alert {
  background-image: data-uri("../assets/images/dds-icons/icon-alert.svg");
}

.icon-edit {
  background-image: data-uri("src\assets\images\dds-icons\dds-edit-icon.svg");
}

.icon-delete {
  background-image: data-uri("src\assets\images\dds-icons\dds-bin-icon.svg");
}

.icon-guidance {
  background-image: data-uri(
    "src\assets\images\dds-icons\dds-icon-guidance.svg"
  );
}

.icon-add {
  background-image: data-uri("src\assets\images\dds-icons\dds-icon-add.svg");
}
.icon-download {
  background-image: data-uri("src\assets\images\dds-icons\download-icon.svg");
}
.icon-bullet-star-green {
  background-image: data-uri("../assets/images/doc-green-bullet.png");
  position: relative;
  top: 0.16rem;
  margin-right: 0.2rem;
}

/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "/src/styles/eclipse-common-classes.less";
@import "/src/styles/icons.less";

html,
body {
  overscroll-behavior: none;
}

html {
  height: 100%;
  font-size: 1rem;
  font-family: Open Sans;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
  min-width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: normal;
  margin: 0;

  .page-content {
    padding: 0;
  }
}

input::-ms-clear {
  display: none;
}

.modal-adaptive .modal-md {
  width: 37.5rem !important; /* 600px */
  max-width: 37.5rem; /* 600px */
}

.modal-md-s.modal-adaptive .modal-md {
  width: 31.25rem !important; /* 500px */
  max-width: 31.25rem; /* 500px */
}

.modal-md-xs.modal-adaptive .modal-md {
  width: 25rem !important; /* 400px */
  max-width: 25rem; /* 400px */
}

input[type="text"] {
  &::-webkit-input-placeholder {
    color: @new-gray-darker !important;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: @new-gray-darker !important;
  }

  &:-ms-input-placeholder {
    color: @new-gray-darker !important;
  }
}

.opportunity-isConfidential {
  padding: 0.15rem 0rem !important;
  font-weight: 600;
  font-size: 0.7rem;
  color: #ED8B00 !important;
  background: #ED8B0014 !important;
}

.opportunity-isConfidential-label{
  color: red;
}